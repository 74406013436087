import { Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function CustomTooltip({ img, HoverToSee, textToHover }) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip-1">{textToHover}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <Button
          variant="transparent"
          {...triggerHandler}
          className="d-inline-flex align-items-center"
        >
          <Image ref={ref} roundedCircle src={img} />
          <span className="ms-1">{HoverToSee}</span>
        </Button>
      )}
    </OverlayTrigger>
  );
}

export default CustomTooltip;

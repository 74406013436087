/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled, { keyframes } from "styled-components";

const animation1 = keyframes`
0% {
    left: -100%;
    }
100% {
    left: 100%;
    }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000;
`;
const ALink = styled.a`
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: ${(props) => props.Cor || "#03e9f4"};
  font-size: 1.2rem;
  font-weight: bold;
  transition: 0.5s;
  overflow: hidden;

  &:hover {
    background-color: #03e9f4;
    color: #050801;
    box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
      0 0 200px #03e9f4;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);

    &:first-child {
      filter: hue-rotate(225deg);
    }
  }
  span {
    position: absolute;
    display: block;

    &:nth-child(1) {
      top: 0;
      left: 0;
      width: 90%;
      height: 1px;
      background: linear-gradient(90deg, transparent, #03e9f4);
      animation: ${animation1} 1s infinite;
    }
  }
`;

const Button = ({ text }) => {
  return (
    <Wrapper>
      <ALink href="">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        {text}
      </ALink>
    </Wrapper>
  );
};

export default Button;

// src/App.jsx
import React from "react";
import Navbar from "./Components/Header/Header";
import "./index.css";
import img from "./assets/backgroundimg2.jpg";
import styled from "styled-components";
import Home from "./Components/HomePage/Home";
import Sobre from "./Components/About/Sobre";
import Footer from "./Components/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import Projects from "./Components/Portfolio/Project";
import Contact from "./Components/Contatos/Contact";

// Criar container com backgroundimage
const Container = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1.5),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    ),
    url(${img});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    background-position: top;
  }
`;

const App = () => {
  return (
    <Container>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contato" element={<Contact />} />
        <Route path="/projetos" element={<Projects />} />
        <Route path="/sobre" element={<Sobre />} /> {/* Página sobre */}
      </Routes>
      <Footer />
    </Container>
  );
};

export default App;

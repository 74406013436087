import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom"; // Importando o Link do react-router-dom
import Button from "../Button";
import styled, { keyframes } from "styled-components";
import logo1 from "../../assets/logo1.png";
import logo from "../../assets/logo.png"; // Não foi utilizado

// Animação keyframes
const animation1 = keyframes`
  0% {
    left: -100%;
  }
  50% {
    left: 0%;
  }
  100% {
    left: -100%;
  }
`;

// Span com animação linear
const SpanAnim = styled.span`
  top: 99%;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  height: 3%;
  position: absolute;
  background: linear-gradient(
    90deg,
    transparent,
    #f39c12,
    transparent,
    #03e9f4,
    transparent
  );
  animation: ${animation1} 10s infinite linear;
  z-index: -1; /* Coloca o span atrás dos outros elementos */
`;

// Div para o logo com background-image
const DivLogo = styled.div`
  width: 80px; /* Reduzido o tamanho da logo */
  height: 80px;
  position: relative;
  background-image: url(${logo1});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 1s;

  &:hover {
    background-image: url(${logo});
    transition: 1s;
  }
`;

// Estilo para centralizar o conteúdo
const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 430px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const DivDesktop = styled.div`
  display: flex;
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Alternância de estado para menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const listNav = ["Home", "Sobre", "Projetos", "Contato"];

  return (
    <nav className="bg-white/30 backdrop-blur-md p-2 relative w-full z-10 border-b border-white/20">
      <SpanAnim />
      <DivDesktop>
        <NavContainer>
          <DivLogo className="text-white font-bold text-xl" alt="Logo" />
          {/* Menu mobile - hambúrguer centralizado */}
          <div className="md:hidden mt-2">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none text-xl" // Tamanho do ícone ajustado
            >
              {isOpen ? "✖" : "☰"} {/* Ícones trocados para abrir/fechar */}
            </button>
          </div>
        </NavContainer>

        {/* Menu desktop */}
        <div className="hidden md:flex space-x-2 justify-evenly mt-2"> {/* Reduzir espaçamento */}
          {listNav.map((item) => (
            <Link to={`/${item.toLowerCase()}`} key={item}>
              <Button text={item} /> {/* Usando Link do react-router-dom */}
            </Link>
          ))}
        </div>
      </DivDesktop>
      {/* Menu dropdown mobile */}
      {isOpen && (
        <motion.div
          className="md:hidden"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 1.3 }}
        >
          {listNav.map((item) => (
            <motion.div
              key={item}
              className="block text-gray-300 hover:text-white py-1 text-center" // Reduzindo padding para os itens do dropdown
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
            >
              <Link to={`/${item.toLowerCase()}`}>
                <Button text={item} /> {/* Usando Link no dropdown mobile */}
              </Link>
            </motion.div>
          ))}
        </motion.div>
      )}
    </nav>
  );
};

export default Navbar;

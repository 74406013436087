// src/Components/Contact.jsx
import React, { useState } from "react";
import styled from "styled-components";
import { fadeIn } from "../About/StyledSobre";

const Container = styled.div`
  margin: 10px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-in-out;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Espaçamento interno */

  h1 {
    color: #ccc;
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    font-weight: bold;
  }
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("https://source.unsplash.com/random/1920x1080"); /* Imagem de fundo */
  background-size: cover;
  filter: blur(8px);
  z-index: -1;
`;

const Title = styled.h1`
  color: #03e9f4;
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Reduz o tamanho do título em telas menores */
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 40px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Reduz o tamanho da descrição em telas menores */
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`;

const Input = styled.input`
  padding: 15px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;

  &:focus {
    outline: none;
    box-shadow: 0 2px 10px rgba(3, 233, 244, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Reduz o tamanho da fonte em telas menores */
  }
`;

const TextArea = styled.textarea`
  padding: 15px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  resize: none;
  transition: box-shadow 0.2s;

  &:focus {
    outline: none;
    box-shadow: 0 2px 10px rgba(3, 233, 244, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Reduz o tamanho da fonte em telas menores */
  }
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: #03e9f4;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    background-color: #0292b4;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #0288a2;
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    font-size: 1rem; /* Reduz o tamanho da fonte em telas menores */
  }
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aqui você pode adicionar a lógica para enviar os dados do formulário
    console.log("Form submitted:", formData);
    alert("Mensagem enviada com sucesso!");
    setFormData({ name: "", email: "", message: "" }); // Limpa o formulário
  };

  return (
    <Container>
      <BackgroundOverlay />
      <Title>Contato</Title>
      <Description>Entre em contato e vamos conversar!</Description>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Seu Nome"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Seu E-mail"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextArea
          name="message"
          placeholder="Sua Mensagem"
          rows="5"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit">Enviar Mensagem</SubmitButton>
      </Form>
    </Container>
  );
};

export default Contact;

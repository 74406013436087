/* eslint-disable jsx-a11y/alt-text */
//  src/Components/Sobre.jsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PreLoader from "../Loading/PreLoader";
import {
  Subtitle,
  DivLogo,
  DivLogoSetion,
  DivRowOne,
  DivCursos,
  PCursos,
  Container,
  TitleCertification,
  Section,
  SkillsList,
  IMGLogo,
  SocialMedia,
  TextPartI,
  TextPartII,
  TextPartIII,
} from "./StyledSobre";
import CustomTooltip from "../Tooltip";

const api = process.env.REACT_APP_API;

const subtitleText = [
  "Desenvolvedor Full Stack",
  "React | Node.js | UX/UI Designer",
  "Criando Soluções Digitais",
  "Projetos que Transformam Ideias",
];

const subtitleAnimation = {
  initial: { y: 100, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1.5 } },
};

const skillsLI = [
  "Bootstrap 5",
  "Figma",
  "JSON",
  "Javascript",
  "MongoDB",
  "React",
  "SASS",
  "CSS3",
  "Git",
  "HTML5",
  "Illustrator",
  "Node.JS",
  "Photoshop",
  "React Bootstrap",
  "Tailwind CSS",
  "Vite",
  "VSC",
];

const Sobre = () => {
  const [currentSubtitle, setCurrentSubtitle] = useState(0);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchInfo = async () => {
    try {
      const response = await axios.get(`${api}/api/sobre`);
      setInfo(response.data);
      setLoading(false);
    } catch (err) {
      setError("Erro ao carregar dados");
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSubtitle((prev) => (prev + 1) % subtitleText.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchInfo();
  }, []);

  if (loading) return <PreLoader />;
  if (error) return <p>{error}</p>;

  return (
    <Container>
      <h1>Sobre Mim</h1>
      <Subtitle
        variants={subtitleAnimation}
        initial="initial"
        animate="animate"
      >
        <AnimatePresence mode="wait">
          <motion.span
            key={currentSubtitle}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {subtitleText[currentSubtitle]}
          </motion.span>
        </AnimatePresence>
      </Subtitle>

      {info && (
        <>
          <DivRowOne>
            <Section>
              <h2>Informações Pessoais</h2>
              <TextPartI>
                Olá, eu sou o <strong>{info.name}</strong>
              </TextPartI>
              <TextPartII>
                Sou um <strong>{info.profession}</strong> apaixonado por criar
                soluções digitais que transformam ideias em realidade.
              </TextPartII>
              <TextPartIII>
                <strong>Estudante de {info.education}</strong>, estou sempre em
                busca de novas tecnologias e desafios para ampliar meus
                conhecimentos e entregar projetos de alta performance. Do
                <strong>front-end</strong> ao <strong>back-end</strong>, estou
                comprometido em desenvolver experiências digitais inovadoras e
                eficientes.
              </TextPartIII>
            </Section>

            <Section>
              <h3>Skills:</h3>
              <SkillsList>
                {info.skills.map((skill, index) => (
                  <CustomTooltip
                    HoverToSee={<IMGLogo src={`${api}${skill}`} />}
                    textToHover={skillsLI[index]}
                  >
                    <DivLogoSetion key={index}>
                      <DivLogo></DivLogo>
                    </DivLogoSetion>
                  </CustomTooltip>
                ))}
              </SkillsList>
            </Section>
          </DivRowOne>

          <Section>
            <h3>Certificações</h3>
            {info.formacoes.map((formacao, index) => (
              <div key={index}>
                <TitleCertification>
                  {formacao.nome} - Duração: {formacao.duracao}
                </TitleCertification>
                <DivCursos>
                  {formacao.cursos.map((curso, cursoIndex) => (
                    <PCursos key={cursoIndex}>
                      {curso.nome}
                      <a
                        href={curso.link_certificado}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver Certificado
                      </a>
                    </PCursos>
                  ))}
                </DivCursos>
              </div>
            ))}
          </Section>

          <Section>
            <h3>Contato</h3>
            <SocialMedia>
              <a
                href={info.socialMedia.github}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub />
              </a>
              <a
                href={info.socialMedia.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
              <a
                href={info.socialMedia.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </SocialMedia>
          </Section>
        </>
      )}
    </Container>
  );
};

export default Sobre;

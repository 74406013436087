/* eslint-disable react/style-prop-object */
import React from "react";
import styled, { keyframes } from "styled-components";

const animate = keyframes`
  0% {
    transform: scale(1);
  }
  80%, 100% {
    transform: scale(0);
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #000209;
`;

const Loader = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
`;

const Span = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(${(props) => `calc(18deg * ${props.index})`});

  &::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #03e9f4;
    box-shadow: 0 0 10px #03e9f4, 0 0 20px #03e9f4, 0 0 30px #03e9f4,
      0 0 40px #03e9f4, 0 0 50px #03e9f4, 0 0 60px #03e9f4, 0 0 70px #03e9f4;
    border-radius: 50%;
    animation: ${animate} 2s infinite linear;
    animation-delay: ${(props) => `calc(0.1s * ${props.index})`};
  }
`;

const PreLoader = () => {
  return (
    <Section>
      <Loader>
        {Array.from({ length: 20 }).map((_, i) => (
          <Span key={i} index={i + 1}></Span>
        ))}
      </Loader>
    </Section>
  );
};

export default PreLoader;

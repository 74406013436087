import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

//  Animação para o título
export const fadeIn = keyframes`
      from {
        opacity: 0;
        transform: translateY(-20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    `;

//Animação para pulsar texto
export const pulse = keyframes`
0% {
transform: scale(1);
}
50% {
transform: scale(1.1);
}`;

export const Subtitle = styled(motion.h2)`
  font-size: 1rem;
  font-weight: 300;
  color: #f3f3f3;
  margin: 20px;
  width: 80%;
  background-color: #222021;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  @media (min-width: 420px) {
    font-size: 1.2rem;
  }
`;

//  Estilização do Container Principal
export const Container = styled.div`
  margin: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-in-out;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo */

  h1 {
    color: #ccc;
    text-align: center;
    font-size: 2rem;
    margin-top: 1rem;
    font-weight: bold;
  }
`;

//  Estilização das Seções
export const Section = styled.section`
  margin: 1.5rem 0;
  padding: 1.5rem;
  width: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center; /* Centraliza o texto das seções */

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }

  h2,
  h3 {
    color: #34495e;
    font-family: "Montserrat", sans-serif;
    text-align: left; /* Alinha os títulos à esquerda */
    font-weight: bold; /* Deixa os títulos em bold */
  }

  p,
  a {
    text-decoration: none;
    transition: color 0.3s ease;
    color: #ddd;
  }
`;

export const TextPartI = styled.p`
  padding: 10px 0.1px;
  border-radius: 8px 8px 0 0;
  border: 0.1px solid #ddd;
  background: #34495e;
  margin: 20px 0px;
  font-size: 1.2rem;
  font-weight: 500;
`;

export const TextPartII = styled.p`
  padding: 20px 10px;
  border: 0.1px solid #ddd;
  color: #34495e !important;
  border: 5px solid #34495e;
  background: #ddd;
  border-radius: 28px 0 28px 0;
  margin-top: 40px !important;
`;

export const TextPartIII = styled.p`
  margin: 40px 0px;
  padding: 20px 10px;
  border-radius: 0 28px 0 28px;
  color: #ddd !important;
  border: 5px solid #ddd;
  background: #34495e;
`;

export const PNome = styled.p`
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  animation: ${pulse} 2.5s infinite;

  strong {
    font-weight: bold;
  }

  @media (min-width: 420px) {
    width: 70%;
  }
`;

export const DivColumnOne = styled.div`
  display: flex;
  padding: 10px 0px;
  width: 100%;
  justify-content: center;
  @media (min-width: 420px) {
    padding: 150px 20px;
  }
`;

export const DivRowOne = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 10px;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`;

//  Estilização da Lista de Certificações
export const SkillsList = styled.div`
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-evenly;

    h3 {
      color: #34495e;
      font-family: "Montserrat", sans-serif;
      text-align: left; /* Alinha os títulos à esquerda */
      font-weight: bold; /* Deixa os títulos em bold */
    }

      &:hover {
        transform: translateY(-3px);
      }
    }
      @media(min-width: 768px) {
      flex-direction: row;
      }
  `;

//  Estilização das Redes Sociais
export const SocialMedia = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
  margin-top: 0.5rem;

  a {
    color: #34495e;
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #e67e22;
    }
  }
`;

export const subtitleText = [
  "Desenvolvedor Full Stack",
  "React | Node.js | UX/UI Designer",
  "Criando Soluções Digitais",
  "Projetos que Transformam Ideias",
];

export const subtitleAnimation = {
  initial: { y: 100, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1.5 } },
};

export const DivLogoSetion = styled.div`
  font-size: 12px;
  margin: 1.5rem 0;
  padding: 0.5rem;
  border-radius: 8px;
  display: grid;
  grid-column: 1fr;
  place-items: center;
  gap: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center; /* Centraliza o texto das seções */
  cursor: pointer;

  h3 {
    color: #34495e;
    font-family: "Montserrat", sans-serif;
    text-align: left; /* Alinha os títulos à esquerda */
    font-weight: bold; /* Deixa os títulos em bold */
  }
  @media (min-width: 420px) {
    font-size: 16px;
  }
`;

export const DivLogo = styled.div`
  display: flex;
  justify-content: center;
  gap: 2px;

  @media (min-width: 420px) {
    gap: 25px;
  }
`;

export const IMGLogo = styled.img`
  width: 60px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  @media (min-width: 420px) {
    width: 70px;
  }
`;

export const DivCursos = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 420px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
`;

export const PCursos = styled.p`
  padding: 12px 5px !important;
  margin: 4px 0;
  color: #34495e !important;
  border: .1px solid #ddd4;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  text-align: center;
  transition: transform 0.5s ease;
  background: rgba(220, 220, 220, 0.1);
  backdrop-filter: blur(8px);

  &:hover {
    transition: 0.1s;
    background-color: #2c3e50;
    color: #ddd !important;
    transition: transform 0.5s ease;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }

  a {
    margin-top: 8px;
    text-decoration: none;
    color: #3498db;
    text-align: center;

    &:hover {
      color: #f39c12;
    }
  }

  @media (min-width: 420px) {
    text-align: start;
    flex-direction: row;
    padding: 6px 5px;

    a {
      text-decoration: none;
      color: #3498db;
      text-align: end;

      margin-top: 0px;
    }
  }
`;

export const TitleCertification = styled.h4`
  font-size: 14px;
  margin-top: 1rem;
  color: #34495e;
  font-weight: bold;
  text-align: center;
  @media (min-width: 420px) {
    text-align: start;
    font-size: 18px;
  }
`;

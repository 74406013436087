// src/Components/Footer.jsx
import React from "react";
import styled, { keyframes } from "styled-components";
import { FaInstagram, FaWhatsapp, FaLinkedin, FaGithub } from "react-icons/fa"; // Importação dos ícones

// Animação da stripe
const animation1 = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
`;

// Estilo do Footer
const FooterContainer = styled.div`
  width: 100%;
  background-color: #222021;
  color: #fff;
  position: relative;
  padding: 20px;
  text-align: center;
  overflow: hidden;
`;

// Estilo da Stripe
const Stripe = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 3px;
  background: linear-gradient(90deg, transparent, #f39c12, transparent);
  animation: ${animation1} 5s infinite;
`;

// Texto no Footer
const FooterText = styled.p`
  font-size: 0.9rem;
  color: #fff;
  margin: 0;
  padding-top: 10px;
`;

// Container dos Ícones
const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

// Estilo dos Ícones
const IconLink = styled.a`
  margin: 0 10px;
  color: #f39c12;
  font-size: 1.8rem;
  transition: color 0.3s ease;

  &:hover {
    color: #03e9f4;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Stripe /> {/* Linha animada */}
      <FooterText>
        © 2024 Thiago Moabi. Todos os direitos reservados.
      </FooterText>
      {/* Ícones de redes sociais */}
      <IconsContainer>
        <IconLink
          href="https://www.instagram.com/thiago.m.freire/"
          target="_blank"
          aria-label="Instagram"
        >
          <FaInstagram />
        </IconLink>
        <IconLink
          href="https://wa.me/5511996787679"
          target="_blank"
          aria-label="WhatsApp"
        >
          <FaWhatsapp />
        </IconLink>
        <IconLink
          href="https://www.linkedin.com/in/thiago-moabi-359885221/"
          target="_blank"
          aria-label="LinkedIn"
        >
          <FaLinkedin />
        </IconLink>
        <IconLink
          href="https://github.com/Tfreire455"
          target="_blank"
          aria-label="GitHub"
        >
          <FaGithub />
        </IconLink>
      </IconsContainer>
    </FooterContainer>
  );
};

export default Footer;

// src/Components/Home.jsx
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { Link } from "react-router-dom"; // Importar o Link do react-router-dom

const Stripe = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  text-align: center;
  height: 3px;
  background: linear-gradient(90deg, transparent, #03e9f4, transparent);
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, transparent, #000914);
  color: #fff;
  text-align: center;
  overflow: hidden;
`;

const Title = styled(motion.h1)`
  font-size: 0.8rem;
  margin: 3px;
  margin-top: 2rem;
  color: #03e9f4;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
  background-color: #222021;
  padding: 10px 20px;
  border-radius: 12px;
  transition: 1.5s;

  &:hover {
    background-color: #03e9f4;
    color: #222021;
    transition: 1.5s;
  }

  @media (min-width: 430px) {
    font-size: 1.1rem;
    padding: 30px 100px;
  }
`;

const Divider = styled(motion.div)`
  height: 1px;
  background: #03e9f4;
  margin: 10px 0;
`;

const Subtitle = styled(motion.h2)`
  font-size: 1.2rem;
  font-weight: 300;
  color: #f3f3f3;
  margin: 3px;
  width: 70%;
  background-color: #222021;
  padding: 20px;
`;

const Button = styled(motion.a)`
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #0f0f0f;
  margin-top: 40px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border-radius: 12px;
  background: #e67e22;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #f39c12;
    transform: scale(1.1);
    transition: 0.3s;
    color: #0f0f0f;
  }
`;

// Animações do Framer Motion
const titleAnimation = {
  initial: { y: -100, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1 } },
};

const dividerAnimation = {
  initial: { width: 0 },
  animate: { width: "80%", transition: { duration: 1 } },
};

const subtitleAnimation = {
  initial: { y: 100, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1.5 } },
};

const buttonAnimation = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 1.2, delay: 0.8 } },
};

const subtitleText = [
  "Desenvolvedor Full Stack",
  "React | Node.js | UX/UI Designer",
  "Criando Soluções Digitais",
  "Projetos que Transformam Ideias",
];

const Home = () => {
  const [currentSubtitle, setCurrentSubtitle] = useState(0);

  // Função para alterar o subtítulo a cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSubtitle((prev) => (prev + 1) % subtitleText.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Title variants={titleAnimation} initial="initial" animate="animate">
        Bem-vindo ao meu Portfólio
      </Title>
      <Divider
        variants={dividerAnimation}
        initial="initial"
        animate="animate"
      />
      <Subtitle
        variants={subtitleAnimation}
        initial="initial"
        animate="animate"
      >
        <Stripe />
        <AnimatePresence mode="wait">
          <motion.span
            key={currentSubtitle}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {subtitleText[currentSubtitle]}
          </motion.span>
        </AnimatePresence>
      </Subtitle>

      {/* Botão de navegação usando Link */}
      <Button
        as={Link} // Usamos `Link` para navegação
        to="/projetos" // Definimos a rota para a página de projetos
        variants={buttonAnimation}
        initial="initial"
        animate="animate"
      >
        Ver Projetos
      </Button>
    </Container>
  );
};

export default Home;
